import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { Col, Container, Row } from 'reactstrap';

import SplashScreen from '../../components/images/splash-screen';
import LogoIcon from '../../components/images/logo-autobahn-network-fff';
import '../01_intro.css';
import './01_intro.css';

const contentContainerStyle = {
  color: 'white',
  margin: '0 auto',
  maxWidth: '920px',
};

const navbarContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  fontWeight: 'bold',
  height: '100px',
  padding: '24px',
  position: 'relative',
  textAlign: 'right',
  top: '0',
  margin: '0% auto',
  maxWidth: '920px',
  width: '100%',
  zIndex: 10,
};

const IntroSection = () => (
  <div className="intro-tech__container">
    <SplashScreen />
    <div className="intro__background" />
    <div style={navbarContainerStyle}>
      <div>
      <Link to="/" style={{ position: 'absolute', left: '24px', top: '50px' }}>
        <FontAwesomeIcon color="#FFF" icon={faArrowAltCircleLeft} size="2x" />
      </Link>
      </div>
      <div style={{ marginTop: '20px' }}>
        <Link to="/">
          {false && (<LogoIcon />)}
        </Link>
      </div>
    </div>
    <div className="intro__content-container">
      <Container style={{ maxWidth: '920px' }}>
        <Row>
          <Col>
            <div style={contentContainerStyle}>
              <h1 className="intro__title">
                Ausstattung
              </h1>
              <h2 className="intro__slogan">Auszeit in Wremen</h2>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </div>
)

export default IntroSection
