import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Section from '../../components/section';

const TerrasseSection = () => (
  <Section title="Terrasse" headerStyle={{ width: '210px' }}>
    <Container>
      <Row>
        <Col>
          <p className="content">
            Von der ruhig gelegenen Terrasse mit Gartenmöbeln und eigenem Strandkorb hat man einen wunderschönen Blick ins Grüne.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <img src="/images/ausstattung/07_Terrasse.JPG" style={{ width: '100%' }} />
        </Col>
      </Row>
    </Container>
  </Section>
);

export default TerrasseSection
