import React from "react"
import { Container, Row, Col } from 'reactstrap';
import SecondLayerConcept from '../../components/images/second-layer-concept';

const containerStyle = {
  margin: `0 auto`,
  maxWidth: 960,
  padding: `0 1.0875rem 0`,
};

const PageIntroSection = () => (
  <div style={containerStyle}>
    <Container>
      <Row>
        <Col>
          <p className="content">
            Das moderne und allergikerfreundliche Nichtraucher-Appartment verfügt über kostenfreies W-LAN/Telefon und hat eine voll ausgestattete Küche mit Geschirrspüler, Kaffee-/Kaffeepad-Maschine (Senseo), Wasserkocher, Toaster, Tiefkühlfach und Backofen.<br />
          </p>
        </Col>
      </Row>
    </Container>
  </div>
);

export default PageIntroSection
