import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Section from '../../components/section';

const WeiteresSection = () => (
  <Section title="Weiteres" headerStyle={{ width: '210px' }}>
    <Container>
      <Row>
        <Col>
          <p className="content">
            Die Böden sind mit hochwertigem Parkett bzw. Fliesen ausgestattet. Haustiere finden bei uns leider keinen Platz.<br />
            <br />
            Ein kostenfreier Parkplatz, Fahrradstellplätze und ein Kinderspielplatz gehören zur Anlage.<br />
            <br />
            <strong>Wahl-Leistungen gegen Gebühr:</strong><br />
            Bei der Buchung besteht die Möglichkeit, Wäschepakete gegen Entgeld zu bestellen.<br />
            Die Ferienwohnung verfügt über Bettdecken mit Überlänge (2,20 m x 1,55 m.) - bitte bedenken Sie dies für den Fall, dass Sie Ihre eigene Bettwäsche mitbringen möchten.<br />
            <br />
            <strong>Verbindliche Nebenkosten:</strong> Kurtaxe
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <img src="/images/ausstattung/12_Abendstimmung.JPG" style={{ width: '100%' }} />
        </Col>
      </Row>
    </Container>
  </Section>
);

export default WeiteresSection
