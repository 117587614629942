import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

import Button from '../components/button';

import IntroSection from '../sections/ausstattung/01_intro';
import PageIntroSection from '../sections/ausstattung/02_pageIntro';
import HausSection from '../sections/ausstattung/03_haus';
import WohnzimmerSection from '../sections/ausstattung/05_Wohnzimmer';
import SchlafzimmerSection from '../sections/ausstattung/06_Schlafzimmer';
import BadSection from '../sections/ausstattung/07_Bad';
import TerrasseSection from '../sections/ausstattung/08_Terrasse';
import WeiteresSection from '../sections/ausstattung/09_Weiteres';

const highlightContainer = {
  backgroundColor: '#62b7f0',
  boxShadow: '0px 16px 40px rgba(31, 41, 51, 0.16)',
  borderRadius: '4px',
  color: '#FFF',
  padding: '48px',
  textAlign: 'center',
  maxWidth: '920px',
  margin: '0 auto',
  marginBottom: '250px',
};

const AusstattungPage = () => (
  <Layout fluidContainer backToHome>
    <SEO
      image="/images/og-image-tech.jpg"
    />

    <IntroSection />
    <PageIntroSection />
    <HausSection />
    <WohnzimmerSection />
    <SchlafzimmerSection />
    <BadSection />
    <TerrasseSection />
    <WeiteresSection />

    <div style={highlightContainer}>
      <p className="content">Jetzt den Urlaub sichern</p>
      <Link to="/reservieren"><Button>Verfügbarkeit prüfen</Button></Link>
    </div>
  </Layout>
)

export default AusstattungPage
