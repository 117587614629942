import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Section from '../../components/section';

const BadSection = () => (
  <Section title="Bad" headerStyle={{ width: '210px' }}>
    <Container>
      <Row>
        <Col>
          <p className="content">
            Im komfortablen Badezimmer befindet sich eine eigene Sauna zur vollkommenen Entspannung in privater Atmosphäre.
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <img src="/images/ausstattung/05_Bad.JPG" style={{ width: '100%' }} />
        </Col>
        <Col md={6}>
          <img src="/images/ausstattung/06_Sauna.JPG" style={{ width: '100%' }} />
        </Col>
      </Row>
    </Container>
  </Section>
);

export default BadSection
