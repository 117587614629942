import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Section from '../../components/section';

const WohnzimmerSection = () => (
  <Section title="Wohnzimmer" headerStyle={{ width: '210px' }}>
    <Container>
      <Row>
        <Col>
          <p className="content">
            Im gemütlichen Wohnbereich steht Ihnen ein Elektrokamin, eine Hifi-Anlage, ein Flachbild-TV und ein Safe zur Verfügung.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <img src="/images/ausstattung/03_Wohnzimmer.JPG" style={{ width: '100%' }} />
        </Col>
      </Row>

      <Row>
        <Col>
          <img src="/images/ausstattung/035_Essecke.JPG" style={{ width: '100%' }} />
        </Col>
      </Row>
    </Container>
  </Section>
);

export default WohnzimmerSection
