import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Section from '../../components/section';

const SchlafzimmerSection = () => (
  <Section title="Schlafzimmer" headerStyle={{ width: '210px' }}>
    <Container>
      <Row>
        <Col>
          <p className="content">
            Im Schlafzimmer steht ein Doppelbett und ein weiterer Flachbildfernseher. Auf Anfrage und gegen Entgeld kann ein Kinderbett und eine Kleinkindausstattung dazu gebucht werden.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <img src="/images/ausstattung/04_Schlafzimmer.JPG" style={{ width: '100%' }} />
        </Col>
      </Row>
    </Container>
  </Section>
);

export default SchlafzimmerSection
