import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Section from '../../components/section';

const HausSection = () => (
  <Section title="Haus" headerStyle={{ width: '210px' }}>
    <Container>
      <Row>
        <Col>
          <img src="https://res.cloudinary.com/dwygmgogv/image/upload/v1388060722/001_Haus_9_nga1mk.jpg" style={{ width: '100%' }} />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <img src="/images/ausstattung/01_Haus.JPG" style={{ width: '100%' }} />
        </Col>
        <Col md={6}>
          <img src="/images/ausstattung/02_Grundriss.png" style={{ width: '100%' }} />
        </Col>
      </Row>
    </Container>
  </Section>
);

export default HausSection
